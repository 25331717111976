
import { Http } from "@/http"

export interface Data {
    store_num: number;
    wait_wash_num: number;
    wait_taken_num: number;
    order_num: number;
    put_num: number;
}

class HttpHome extends Http {
    get_data = () => {
        return this.get<Data>({}, `/admin/index/`);
    }
}

export const api_home=  new HttpHome('')
