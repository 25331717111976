







































import { Vue, Component, Watch } from "vue-property-decorator";
import { api_home, Data } from "@/http/home";
@Component
export default class extends Vue {
  data: Data = {
    store_num: 0,
    wait_wash_num: 0,
    wait_taken_num: 0,
    order_num: 0,
    put_num: 0,
  };
  goToPut(){
    this.$router.push('/warehouse/put')
  }
  async getData() {
    try {
      const response = await api_home.get_data();
      this.data = { ...response };
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  created() {
    this.getData();
  }
}
